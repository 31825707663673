<template>
  <aside class="white-container">
    <header>
      <p>{{ title }}</p>

      <a @click.prevent="handleRouteChange(link, withTag, withSystemTag)" style="cursor: pointer;">
        {{ $store.getters.GET_LANG ? 'Посмотреть все' : 'All' }}
        <img src="/assets/icons/arrow.svg">
      </a>
    </header>

    <tricolor-line class="tricolor"/>

    <activities-photo-news-dynamic
            class="card_important"
            v-for="(item, i) in getNews"
            :key="`${i}news`"
            :card-link="cardLink"
            :card="item"/>
  </aside>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import ActivitiesPhotoNewsDynamic from '@/components/activitiesSurveys/ActivitiesPhotoNewsDynamic'

export default {
  name: 'SimilarBlockRegion',

  props: {
    title: {
      type: String,
      required: true
    },

    link: {
      type: String,
      required: true
    },

    withTag: {
      type: String,
      required: false,
      default: ''
    },

    withSystemTag: {
      type: String,
      required: false,
      default: ''
    },

    getNews: {
      type: Array,
      required: false
    },

    cardLink: {
      type: String,
      required: false,
      default: 'news'
    }
  },

  methods: {
    handleRouteChange (link, withTag, withSystemTag) {
      if (withTag) {
        this.$store.commit('setFilterTag', withTag)
      }

      if (withSystemTag) {
        this.$store.commit('setFilterSystemTagList', withSystemTag)
      }

      this.$router.push(link)
    }
  },

  components: {
    ActivitiesPhotoNewsDynamic,
    TricolorLine
  }
}
</script>
<style scoped lang="scss">

  aside {
    display: flex;
    flex-direction: column;
    width: 27.125rem !important;
    padding: 1.88rem 3.125rem 1.88rem 1.88rem;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .75rem;

      p {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1rem;
        text-transform: uppercase;
        color: #1F3245;
      }

      a {
        font-size: .875rem;
        line-height: 1.375rem;
        color: #246CB7;

        > img {
          transform: rotate(-90deg);
        }
      }
    }

    .tricolor {
      margin-bottom: 1.375rem;
    }

    .similar__block {
      margin-bottom: 1rem;
    }
  }
  .card_important{
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #eee;
  }
  .card_important:last-child{
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    .aside {
      display: none !important;
    }
  }
  @media screen and (max-width: 420px){
    aside {
      display: none;
    }
  }

</style>
