<template>
    <router-link :to="`/${cardLink}/${card.id}`" class="importan-content">
      <figure v-if="card.preview_photo" :style="`background: url('${card.preview_photo}') center / cover`"></figure>
      <figure v-else-if="card.preview_media" :style="`background: url('${card.preview_media}') center / cover`"></figure>
      <article>
        <p v-trim="Number(trim)" class="importan-content__title">{{ $store.getters.GET_LANG ? card.title: card.title_eng }}</p>
        <p v-if="card.date_time" class="importan-content__date">{{ getFormattedDate(new Date(card.date_time)) }}</p>
        <p v-else-if="card.date" class="importan-content__date">{{ getFormattedDate(new Date(card.date)) }}</p>
      </article>
    </router-link>

</template>

<script>
import formatDate from '@/mixins/formatDate'

export default {
  name: 'ActivitiesPhotoNewsDynamic',
  mixins: [formatDate],
  props: {
    card: {
      type: Object,
      default: () => ({
        date: null,
        title: null,
        subtitle: null,
        photo: null
      })
    },
    trim: {
      type: [Number, String],
      default: 3
    },
    cardLink: {
      type: String,
      required: false,
      default: 'news'
    }
  }
}
</script>

<style scoped lang="scss">

  .importan-content {
    width: 100%;
    display: flex;

    figure {
      width: 8.4375rem;
      height: 5.625rem;
      border-radius: 0.5rem;
      flex-shrink: 0;

      margin-right: 1.25rem;
    }

    article {
      width: auto;
      font-size: 0.875rem;
      line-height: 157%;
    }

    &__date {
      color: #5E686C;
      opacity: 0.8;
    }

    &__title {
      font-weight: 600;
      color: #1F3245;

      margin-bottom: 0.425rem;
    }

  }

</style>
