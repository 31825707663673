<template>
    <div>
        <a :href="`${dataFile.url}`" target="_blank">Скачать {{formatBytes(dataFile.size)}}</a>
    </div>
</template>
<script>
import formatSizeFile from '@/mixins/formatSizeFile'
export default {
  name: 'DownloadFile',
  mixins: [formatSizeFile],
  props: {
    dataFile: {
      define: true
    }
  }
}
</script>
<style lang="scss" scoped>
a{
    font-size: .81rem;
    line-height: 1.25rem;
    color: #246CB7;
    padding-left: 1.625rem;
    position: relative;
}
a:before{
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: -0.05rem;
    left: 0;
    background: url('../assets/icons/download.svg') center center no-repeat;
    background-size: .75rem;
}
</style>
