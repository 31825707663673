<template>
    <div class="partner-info">
        <div class="img_block"></div>
        <div class="info_block">
            <p>{{content.name}}</p>
            <download-file :dataFile="{url: content.url, size: content.size, type: content.type}" class="download-file"/>
        </div>
    </div>
</template>
<script>
import DownloadFile from '@/components/DownloadFile'
export default {
  name: 'DocumentsInfo',
  components: {
    'download-file': DownloadFile
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: null,
          url: null,
          size: null,
          type: null
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // margin-bottom: 1.875rem;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.info_block{
    margin-left: 1.25rem;
    width: 41.313rem;
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
}
.download-file{
    margin-top: 1rem;
}
@media screen and (max-width: 768px){
    .info_block{
        width: calc(100% - 1.25rem - 2.82rem);
    }
    .download-file{
        margin-top: .75rem;
    }
}
</style>
